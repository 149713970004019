import React, { useContext, useEffect, useState } from "react";
import Context from "../../context/Context";

const leadingZero = input => {
  if (String(input).length === 1) {
    return "0" + String(input);
  }
  return String(input);
};

const TimerComponent = ({
  display,
  initialDays = "00",
  initialHours = "00",
  initialMinutes = "00",
  initialSeconds = "00"
}) => {
  const { countDownEnd } = useContext(Context);

  // intervalTime, days, hours , minutes, seconds, init
  const [intervalState, setIntervalState] = useState([
    0,
    initialDays,
    initialHours,
    initialMinutes,
    initialSeconds,
    true
  ]);

  const countDownEndTime = new Date(countDownEnd).getTime();

  useEffect(() => {
    if (intervalState[0] >= 0) {
      const timer = setTimeout(() => {
        const currentTime = new Date().getTime();
        const intervalTime = countDownEndTime - currentTime;

        const days = leadingZero(
          Math.floor(intervalTime / (1000 * 60 * 60 * 24))
        );
        const hours = leadingZero(
          Math.floor((intervalTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        );
        const minutes = leadingZero(
          Math.floor((intervalTime % (1000 * 60 * 60)) / (1000 * 60))
        );
        const seconds = leadingZero(
          Math.floor((intervalTime % (1000 * 60)) / 1000)
        );

        const init = intervalState[5];
        if (init) {
          setTimeout(() => {
            setIntervalState([
              intervalTime,
              days,
              hours,
              minutes,
              seconds,
              false
            ]);
          }, 1000);
        }

        setIntervalState([intervalTime, days, hours, minutes, seconds, init]);
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  const [, days, hours, minutes, seconds] = intervalState;

  const days2 = days > 0 ? days : leadingZero(0);
  const hours2 = hours > 0 ? hours : leadingZero(0);
  const minutes2 = minutes > 0 ? minutes : leadingZero(0);
  const seconds2 = seconds > 0 ? seconds : leadingZero(0);

  let text = `${minutes2}:${seconds2}`;
  switch (display) {
    case "minutes":
      text = `${minutes2}`;
      break;
    case "seconds":
      text = `${seconds2}`;
      break;
    case "default-with-hours":
      text = `${hours2}:${minutes2}:${seconds2}`;
      break;
    case "default-with-days":
      text = `${days2}:${hours2}:${minutes2}:${seconds2}`;
      break;
    default:
      break;
  }

  return <div>{text}</div>;
};

export default TimerComponent;
